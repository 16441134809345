<template>
  <div class="calendar-container">
    <div v-if="!smallCalendar" class="text-center section" style="flex: 7">
      <v-calendar
        class="custom-calendar max-w-full noselect"
        :attributes="events"
        disable-page-swipe
        is-expanded
        @dayclick="clickDay"
        :first-day-of-week="2"
        :masks="{ title: 'MMMM YYYY'}"
        :locale="$i18n.locale"
      >
        <template v-slot:day-content="{ day, attributes, dayEvents }">
          <div class="day-container" v-on="dayEvents">
            <p class="day-label text-xs" :class="{'current-selected-day': isSelectedDay(day)}">{{ day.label }}</p>
            <p class="day-content" v-for="attr in attributes" :key="attr.key">
              {{ attr.customData.title }}
            </p>
          </div>
        </template>
      </v-calendar>
    </div>
    <div v-else>
      <v-date-picker v-model="date" 
                     style="margin: 20px auto"
                     :select-attribute="{
                          highlight: {
                              start: { fillMode: 'solid', style: { background: '#e5b409' } },
                          },
                      }"
                      @dayclick="clickDay"
                     :first-day-of-week="2"
                     :attributes="events"
                     :masks="{ title: 'MMMM YYYY'}"
                     :locale="$i18n.locale"/>
    </div>
    <calendar-day :events="selectedDayEntries" :day="selectedDayTitle" style="flex: 3" @callback="(event) => $emit('callback', event)"/>
  </div>
  

</template>

<script>
import CalendarDay from './calendar/CalendarDay.vue'
import moment from 'moment'

export default {
  methods: {
    clickDay(e) {
      this.currentDay = e.id
    },
    isSelectedDay(day) {
      return moment(day.id).isSame(moment(this.currentDay), 'day')
    }
  },
  components: {
    CalendarDay
  },
  data() {
    
    return {
      currentDay: new Date(),
    };
  },
  computed: {
    smallCalendar() {
      return window.innerWidth <= 700
    },
    selectedDayEntries() {
      const entries = this.$store.state.events.filter(e => 
        moment(e.date).isSame(moment(this.currentDay), 'day')
      )
      return entries
    },
    selectedDayTitle() {
      moment.locale(this.$i18n.locale)
      return moment(this.currentDay).format('MMMM DD, YYYY')
    },
    events() {
      const calendarEntries = this.$store.state.events.filter((e) => {
        if(this.$i18n.locale !== 'sv') {
          return e.textEng
        }
        return true
      })
      calendarEntries.sort((a, b) => a.date.localeCompare(b.date))
      const result = []

      var key = 0
      for(const e of calendarEntries) {
        var t = this.$i18n.locale === 'en' ? e.textEng : e.textSwe
        // if(t.length > 15) t = t.substring(0, 13) + '...'

        result.push({
          key: key++,
          dates: new Date(e.date),
          customData: {
            title: t,
            event: e,
          },
          dot: this.smallCalendar ? {
            style: { 
              backgroundColor: '#e5b409'
            },
          } : null,
          // highlight: {
          //   style: { 
          //     backgroundColor: '#e5b409'
          //   },
          // }
        })
      }

      return result
    }
  }
};
</script>

<style>
.calendar-container {
  display: flex;
}

.custom-calendar.vc-container {
  border-radius: 20px !important;
  overflow: hidden;
}
.scrollbar {
  width: 0px;
}
.scrollbar-track {
  display: none;
}
.custom-calendar.vc-container {
  border-radius: 0;
  width: 100%;
}
.custom-calendar.vc-container .vc-header {
  background-color: #ffffff;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
}
/* .vc-weekday >>> .day-container {
  background-color: #0b3d6e;
  border-bottom: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
  padding: 5px 0;
} */
.custom-calendar.vc-container .vc-day {
  display: flex;
  flex-direction: column;
  /* padding: 0 5px 3px 5px; */
  margin: 0;
  text-align: left;
  min-height: 90px;
  max-height: 90px;
  width: 100%;
  /* min-width: 90px; */
  background-color: white;
  font-size: 10px;
  cursor: pointer;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 5px;
}

.day-container {
  height: 100%;
  width: 100%;
}
/* .day-container .day-content {
  margin: 0;
  background-color: #e5b409;
} */

.custom-calendar.vc-container .vc-day:hover {
  background-color: #f8dd7c !important;
}

.current-selected-day {
  color: #e5b409;
  font-weight: bolder;
}




.day-content {
  /* position: absolute; */
  /* display: flex; */
  /* justify-content: center; */
  top: 15px;
  left: 0px;
  color: white;
  background-color: #e5b409;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  width: 95%;
  box-sizing: border-box;
  font-size: calc(6px + 0.6vw);
  margin: 5px auto;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.day-label {
  margin: 0;
}

.custom-calendar.vc-container .vc-title {
  text-transform: capitalize;
}
.custom-calendar.vc-container .vc-day.weekday-1 {
  background-color: #fff3c7;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: 1px solid #dfdfdf;
}
.custom-calendar.vc-container .vc-day:not(.on-bottom).weekday-1 {
  border-bottom: 1px solid #dfdfdf;
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: 1px solid #dfdfdf;
}
.custom-calendar.vc-container .vc-day-dots {
  margin-bottom: 5px;
}

@media screen and (max-width: 700px) {
  .calendar-container {
    flex-direction: column;
  }

  .custom-calendar.vc-container {
    border-radius: 0px !important;
    overflow: hidden;
  }

  .custom-calendar.vc-container .vc-day {
    /* padding: 0 5px 3px 5px; */
    text-align: left;
    min-height: 80px;
    max-height: 80px;
    /* width: 80px; */
    overflow-x: hidden;
    background-color: white;
    font-size: 10px;
    cursor: pointer;
    padding: 0;
  }

  .day-content {
    font-size: 10px;
    justify-content: flex-start;
    text-align: left;
  }

  .day-label {
    margin: 0 3px;
  }
}
</style>