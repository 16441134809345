var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-container"},[(!_vm.smallCalendar)?_c('div',{staticClass:"text-center section",staticStyle:{"flex":"7"}},[_c('v-calendar',{staticClass:"custom-calendar max-w-full noselect",attrs:{"attributes":_vm.events,"disable-page-swipe":"","is-expanded":"","first-day-of-week":2,"masks":{ title: 'MMMM YYYY'},"locale":_vm.$i18n.locale},on:{"dayclick":_vm.clickDay},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
var day = ref.day;
var attributes = ref.attributes;
var dayEvents = ref.dayEvents;
return [_c('div',_vm._g({staticClass:"day-container"},dayEvents),[_c('p',{staticClass:"day-label text-xs",class:{'current-selected-day': _vm.isSelectedDay(day)}},[_vm._v(_vm._s(day.label))]),_vm._l((attributes),function(attr){return _c('p',{key:attr.key,staticClass:"day-content"},[_vm._v(" "+_vm._s(attr.customData.title)+" ")])})],2)]}}],null,false,2376647570)})],1):_c('div',[_c('v-date-picker',{staticStyle:{"margin":"20px auto"},attrs:{"select-attribute":{
                        highlight: {
                            start: { fillMode: 'solid', style: { background: '#e5b409' } },
                        },
                    },"first-day-of-week":2,"attributes":_vm.events,"masks":{ title: 'MMMM YYYY'},"locale":_vm.$i18n.locale},on:{"dayclick":_vm.clickDay},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('calendar-day',{staticStyle:{"flex":"3"},attrs:{"events":_vm.selectedDayEntries,"day":_vm.selectedDayTitle},on:{"callback":function (event) { return _vm.$emit('callback', event); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }