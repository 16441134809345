<template>
  <div class="calendar-day-wrapper">
    <h3 style="text-align: left; margin: 10px 0">{{ day }}</h3>
    <div v-if="events.length > 0">
      <article v-for="event in events" :key="event.date" class="calendar-day-container" @click="emitCallback(event)">
        <p class="calendar-day-date">{{getDate(event.date)}}</p>
        <p class="calendar-day-content">{{getContent(event)}}</p>
      </article>
    </div>
    <div v-else class="calendar-day-container">
      <p class="calendar-day-content text-light">{{ $t('calendar.no-selection') }}</p>
    </div>
  </div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'
import moment from 'moment'

export default {
  mixins: [smoothReflow],
  props: {
    day: '',
    events: [],
  },
  mounted(){
    this.$smoothReflow()
  },
  methods: {
    getDate(date) {
      moment.locale('sv') //TODO Make this work with dynamic locale
      return moment(date).format('HH:mm')
    },
    getContent(event) {
      const text = this.$i18n.locale === 'en' ? event.textEng : event.textSwe

      return text
    },
    emitCallback(event) {
      this.$emit('callback', event)
    }
  }
}
</script>

<style>
.calendar-day-wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: 30px;
}

.calendar-day-container {
  width: 100%;
  margin: 20px auto;
  text-align: left;
}

.calendar-day-date {
  font-size: 16px;
  font-weight: lighter;
  margin: 0;
}

.calendar-day-content {
  font-size: 20px;
  margin: 0;
  white-space: pre-wrap;
}

@media screen and (max-width: 700px) {
  .calendar-day-wrapper {
    margin-left: 0;
  }
  
}
</style>